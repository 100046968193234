/* global.css */
body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400; /* Regular for general text */
    cursor: default;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: 800; /* ExtraBold for headlines */
  }
  
  .strong, b, strong {
    font-weight: 700; /* Bold for highlights */
  }
  
  .caption {
    font-style: italic; /* Italic for captions */
  }