/* Overview Module */
@keyframes ovFadeInLeft {
  from {
      opacity: 0;
      transform: translateX(-80px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

.ovFadeInLeft {
  opacity: 0; 
  animation-name: ovFadeInLeft;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
}

@keyframes ovFadeInRight {
  from {
      opacity: 0;
      transform: translateX(80px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

.ovFadeInRight {
  opacity: 0; 
  animation-name: ovFadeInRight;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
}

@keyframes ovFadeInRightLi {
  from {
      opacity: 0;
      transform: translateX(100px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

.ovFadeInRightLi {
  opacity: 0; 
  animation-name: ovFadeInRightLi;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}




/*Card Module*/
@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
}

/*Ai Module*/
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fadeIn {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
}

