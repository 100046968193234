.toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  
    .nav-links {
      display: flex;
      align-items: center;
      justify-content: right;
      width: 100%;
  
      .link {
        color: inherit;
        text-decoration: none;
        margin: 0 10px;
        font-size: 1rem;
        transition: color 0.2s ease; 
        animation: fadeInEffect 0.2s ease forwards;
  
        &:hover {
          color: #c72127; 
        }
      }
    }
  }

.navbar-header {
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;

  a.navbar-brand {
    margin-right: -15px;
  }

  img {
    height: 50px;
    margin-right: 20px; 
  }
}

  @keyframes fadeInEffect {
    from { opacity: 0; }
    to { opacity: 1; }
  }

.navbar-header {
    display: flex;
    align-items: center;
  }
  
  .pulse-line-container {
    width: 150px; 
    height: auto;
    margin-left: 20px; 
  }
  
  @keyframes pulseAnimation {
    0% {
        stroke-dasharray: 1000, 25; 
        stroke-dashoffset: 1000; 
    }
    100% {
        stroke-dasharray: 1000, 25; 
        stroke-dashoffset: 0; 
    }
  }
  
  .cls-1 {
    fill: none;
    stroke: #c72127;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 7px;
    animation: pulseAnimation 2s linear infinite;
  }