.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fafbfd; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pulse-line-container {
  width: 150px; 
  height: auto;
  margin-left: 1px; 
}

@keyframes pulseAnimation {
  0% {
      stroke-dasharray: 1000, 25; 
      stroke-dashoffset: 1000; 
  }
  100% {
      stroke-dasharray: 1000, 25; 
      stroke-dashoffset: 0; 
  }
}

.cls-1 {
  fill: none;
  stroke: #c72127;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 7px;
  animation: pulseAnimation 2s linear infinite;
}

@keyframes fadeOut {
  from {
      opacity: 1;
      pointer-events: all;
  }
  to {
      opacity: 0;
      pointer-events: none;
  }
}

.preloader.fadeOut {
  animation: fadeOut 1s ease forwards;
}