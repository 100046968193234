// Define color variables
$gold: #c39c5d;
$red: #c72127;
$navy: #2b4265;
$silver: #e0e0e0;
$charcoal: hsl(0, 0%, 17%);
$snow: #fafbfd;

.animated-element {
  opacity: 0;
}

.fadeIn, .fadeInLeft {
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeInLeft {
  animation-name: fadeInLeft;
  transform: translateX(-50px);
}

.fadeInDelayed {
  animation-name: fadeIn !important;
  animation-delay: 2s !important; 
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInLeft {
  from { opacity: 0; transform: translateX(-50px); }
  to { opacity: 1; transform: translateX(0); }
}

@keyframes rippleEffect {
    from { transform: scale(1); opacity: .8; }
    to { transform: scale(1.8); opacity: 0; }
}

.pulsing-button {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    &::before, &::after {
      content: '';
      position: absolute;
      border: 8px solid $snow;
      border-radius: 50%;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
      animation: rippleEffect 3s infinite ease-out;
    }
    &::after { animation-delay: 1s; }
}

.pulsing-button > * { position: relative; z-index: 1; }

.check-icon { color: $red; }

.intro-container { margin-left: 50px; padding-left: 100px; }

.tbld-logo {
  max-width: 500px;
}
