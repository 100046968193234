// Define color variables
$gold: #c39c5d;
$red: #c72127;
$navy: #2b4265;
$silver: #e0e0e0;
$charcoal: #2b2b2b;
$snow: #fafbfd;

.main-content {
  display: block;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  background: $snow;
}

.intro {
  padding-top: 10%;
}

.ai {
  width: 100%;
  padding-bottom: 50px;
}

.intro-background,
.overview-background,
.modules-background {
  width: 100%;
  padding: 100px;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 4%;
}

.intro-background {
  position: relative;
  //background: linear-gradient(to right, $navy, $charcoal);
  background: linear-gradient(90deg, rgba(0, 35, 89, 1) 0%, #2b4265 49%);
  width: 100%;
  padding: 100px;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    bottom: -380px;
    left: 0;
    right: 0;
    height: 500px;
    background: url("../assets/images/landing-page.svg") no-repeat bottom;
    background-size: cover;
    z-index: 2;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -400px;
    left: 0;
    right: 0;
    height: 500px;
    background: url("../assets/images/landing-page.svg") no-repeat bottom;
    background-size: cover;
    opacity: 0.6;
    z-index: 1;
  }


  .white-cover {
    position: absolute;
    bottom: 50;
    left: 0;
    right: 0;
    height: 200px; 
    background: $snow; 
    z-index: 0; 
  }

  .intro {
    z-index: 3;
  }
}

.overview-background {
  position: relative;
  background: url("../assets/images/hex-pattern1.svg") no-repeat bottom;
  background-size: 28%;  
  background-position: left 6% bottom 29%;  
  z-index: 3;
  padding-bottom: 300px;
  padding-top: 300px;
}

.modules-background {
  position: relative;
  background: url("../assets/images/hex-pattern2.svg") no-repeat bottom;
  background-size: 22%;  
  background-position: right 6% bottom 25%;  
  z-index: 0;
  padding-bottom: 300px;
}

.ai-background {
  background: linear-gradient(90deg, rgba(0, 35, 89, 1) 0%, #2b4265 49%);
  padding: 50px;
  padding-top: 5%;
}

.chatbot-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; 
  border: none;
  background-color: #e0e0e0;
  border: 4px solid #4c73ae;
  border-radius: 50%;
  box-shadow: 2px 4px 8px rgba(0,0,0,0.3);
  padding: 5px;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;

  img {
      width: 30px; 
      height: 30px; 
  }
}



#rasaWidget {
    z-index: 1000;
    position: fixed;
    cursor: pointer;
}

#rasaWidget > div:first-child > div:first-child > div:nth-child(2) > div:first-child {
    font-size: 18px;
    color: #fafbfd;
}

#rasaWidget > div:first-child > div p{
    font-size: 15px;
    color: #fafbfd;
}

#rasaWidget > div:first-child{
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

#rasaWidget > div:first-child > div:nth-child(2) > div:nth-child(even) p{
    color: #fafbfd;
    font-size: 15px;
}

#rasaWidget > div:first-child > div:nth-child(2) > div:nth-child(odd) p{
    color: #2b2b2b;
    font-size: 15px;
}

#rasaWidget > div:first-child > div:nth-child(2){ 
  z-index: 100; 
  background-image: url('../../public/plugin/hex-pattern1.svg');
}
