.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(90deg, rgba(0, 35, 89, 1) 0%, #2b4265 49%);
  text-align: center;
  z-index: 1000;
  font-size: 14px;
  color: #fafbfd;

  p {
    margin-bottom: 10px; 
  }

  .footer_logo_container {
    display: flex;
    justify-content: center; 
    gap: 10px; 

    img {
      width: auto; 
      height: 50px; 
      margin: 10px; 
    }
  }
}